import { ChangeEvent, FC, ReactElement } from "react";
import { BetStake } from "@/components/BetStake/BetStake";
import { BetType } from "@/components/BetType/BetType";
import { isValidIntegerOrDecimal, twoDecimalFloor } from "@/lib/number";
import { getActiveBrand } from "@/lib/brand";
import { stakeAmountsMapping } from "@/constants/stakeSelector";

export const Bookies: FC<{
  stakeValue: string;
  stakeInput: "button" | "input";
  // eslint-disable-next-line no-unused-vars
  setStakeInput: (value: "button" | "input") => void;
  // eslint-disable-next-line no-unused-vars
  setStakeValue: (value: string) => void;
  selectionCount: number;
  children: ReactElement;
}> = ({
  stakeValue,
  setStakeValue,
  stakeInput,
  setStakeInput,
  selectionCount,
  children,
}): ReactElement => {
  const handleStakeClick = (event: React.MouseEvent): void => {
    const value = (event.target as HTMLButtonElement).value;
    setStakeInput("button");
    setStakeValue(value);
  };

  const handleStakeChange = (event: ChangeEvent): void => {
    setStakeInput("input");

    const value = (event.target as HTMLInputElement).value;

    if (!isValidIntegerOrDecimal(value)) {
      return;
    }

    setStakeValue(value);
  };

  const handleInputFocus = () => {
    setStakeValue("");
  };

  const convertTo2Decimals = (event: ChangeEvent): void => {
    const value = (event.target as HTMLInputElement).value;

    if (!value) {
      return;
    }

    const result = twoDecimalFloor(Number(value));
    setStakeValue(result);
  };

  if (selectionCount === 0) {
    return (
      <BetType
        selectionCount={selectionCount}
        emptySelectionsMessage="No Bets Added"
        isEmptySelections={true}
      />
    );
  }

  const brand = getActiveBrand();

  return (
    <>
      <BetType selectionCount={selectionCount} />
      <BetStake
        handleStakeClick={handleStakeClick}
        handleStakeChange={handleStakeChange}
        handleInputFocus={handleInputFocus}
        convertTo2Decimals={convertTo2Decimals}
        stakeAmounts={stakeAmountsMapping.get(brand) ?? ["5", "10", "25", "50"]}
        stakeLabel={"Stake"}
        stakeValue={stakeValue}
        stakeInput={stakeInput}
      />
      {children}
    </>
  );
};
