import { ChangeEvent, FC } from "react";
import { selectCurrencySymbol } from "@/lib/content";
import styles from "./BetStakeInput.module.scss";

interface BetStakeInputProps {
  // eslint-disable-next-line no-unused-vars
  handleStakeChange: (event: ChangeEvent) => void;
  handleInputFocus: () => void;
  // eslint-disable-next-line no-unused-vars
  convertTo2Decimals: (event: ChangeEvent) => void;
  locale: string;
  stakeValue: string;
  stakeInput: string;
}

export const BetStakeInput: FC<BetStakeInputProps> = ({
  locale,
  stakeValue,
  stakeInput,
  handleStakeChange,
  handleInputFocus,
  convertTo2Decimals,
}) => {
  const currencySymbol = selectCurrencySymbol(locale);

  return (
    <div
      className={styles.inputWrapper}
      data-testid="inputWrapper"
      // Used within the styles as we cannot use ::before inline
      data-before-content={currencySymbol}
      style={{ content: `"${currencySymbol}"` }}
    >
      <input
        className={styles.input}
        type="number"
        name="otherAmount"
        id="otherAmount"
        onChange={handleStakeChange}
        onFocus={handleInputFocus}
        onBlur={convertTo2Decimals}
        value={stakeInput === "input" ? stakeValue : ""}
      />
    </div>
  );
};
