import React, { ReactElement, useState } from "react";
import Image from "next/image";
import { fastBetOffers, offerMap } from "@/constants/bet";
import { FastBetDescriptions, OfferDescriptions } from "@/types/betslip";
import fastBetIcon from "@/public/images/fast_bet.png";
import styles from "./BookieOffer.module.scss";

interface BookieOfferProps {
  offer: string;
  bookmaker: string;
}

export const BookieOffer = ({
  offer,
  bookmaker,
}: BookieOfferProps): ReactElement => {
  const [displayOffer, setDisplayOffer] = useState<boolean>(false);

  const toggleOfferDisplay = async () => {
    setDisplayOffer(!displayOffer);
  };

  const handleBlur = () => {
    setDisplayOffer(false);
  };

  return (
    <>
      <button
        onClick={toggleOfferDisplay}
        onBlur={handleBlur}
        key={offer}
        id={offer}
        className={`${
          offer === "fastbet" ? styles.offerButton__fastBet : styles.offerButton
        }`.trim()}
      >
        {offer === "fastbet" && (
          <div className={styles.imageWrapper}>
            <Image
              width={17}
              height={15}
              src={fastBetIcon}
              alt="fast bet icon"
            />
          </div>
        )}
        {offer === "fastbet"
          ? fastBetOffers.header
          : offerMap[offer as keyof OfferDescriptions].header}
      </button>
      {displayOffer && (
        <div
          className={`${
            offer === "fastbet"
              ? styles.offerDisplay__fastBet
              : styles.offerDisplay
          }`.trim()}
        >
          <h5 className={styles.header}>
            {offer === "fastbet"
              ? fastBetOffers.header
              : offerMap[offer as keyof OfferDescriptions].header}
          </h5>
          <p className={styles.paragraph}>
            {offer === "fastbet"
              ? fastBetOffers.description[
                  bookmaker as keyof FastBetDescriptions
                ]
              : offerMap[offer as keyof OfferDescriptions].description}
          </p>
        </div>
      )}
    </>
  );
};
