import React, { ChangeEvent, ReactElement } from "react";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import { isLocaleInUs, selectCurrencySymbol } from "@/lib/content";
import styles from "@/components/BetStake/BetStake.module.scss";
import { BetStakeInput } from "@/components/BetStakeInput/BetStakeInput";
import { gaEvent } from "@/lib/gtag";
import { useCheckDesktopDevice } from "@/hooks/Window";

interface BaseBetStakeProps {
  // eslint-disable-next-line no-unused-vars
  handleStakeClick: (event: React.MouseEvent) => void;
  // eslint-disable-next-line no-unused-vars
  handleStakeChange?: (event: ChangeEvent) => void;
  // eslint-disable-next-line no-unused-vars
  convertTo2Decimals?: (event: ChangeEvent) => void;
  handleInputFocus?: () => void;
  stakeAmounts: string[];
  stakeLabel: string;
  stakeValue: string;
  stakeInput?: string;
}

interface BetStakePropsWithBetBuilderTipsterStyling {
  isBetBuilderTipster?: boolean;
  isStatsTipster?: never;
}
interface BetStakePropsWithStatsTipsterStyling {
  isBetBuilderTipster?: never;
  isStatsTipster?: boolean;
}

type BetStakeProps = BaseBetStakeProps &
  (
    | BetStakePropsWithBetBuilderTipsterStyling
    | BetStakePropsWithStatsTipsterStyling
  );

export const BetStake = ({
  handleStakeClick,
  handleStakeChange,
  convertTo2Decimals,
  handleInputFocus,
  stakeAmounts,
  stakeLabel,
  stakeValue,
  stakeInput,
  isBetBuilderTipster,
  isStatsTipster,
}: BetStakeProps): ReactElement => {
  const [locale] = useLocaleFromRouter();
  const currencySymbol = selectCurrencySymbol(locale);
  const isDesktopDevice = useCheckDesktopDevice();

  const isBetBuilderOrStatsTipster = isBetBuilderTipster || isStatsTipster;

  // If the user is viewing the BetStake component via a tipster
  // we want to append the word "Select" to the stake label
  // if the user is on desktop and not in the US
  // e.g. Select Stake
  const label =
    isBetBuilderOrStatsTipster && isDesktopDevice && !isLocaleInUs(locale)
      ? `Select ${stakeLabel}`
      : stakeLabel;

  const stakeContainerClassName = `${
    isBetBuilderOrStatsTipster
      ? styles.enableTipsterStyling
      : styles.disableTipsterStyling
  } ${styles.stakeContainer}`;

  const stakeSelectorClassName = `${
    isBetBuilderOrStatsTipster
      ? styles.enableStatsTipsterStyling
      : styles.disableTipsterStyling
  } ${styles.stakeSelector}`;

  const gaStakeSelectionEvent = (e: React.MouseEvent): void => {
    if (isBetBuilderOrStatsTipster) return;

    const value = (e.target as HTMLButtonElement).value;

    gaEvent("betslip_stake_selected", { stake_selected: value });
  };

  return (
    <div className={stakeContainerClassName}>
      <div
        className={styles.stakeSelectorContainer}
        data-testid="stakeSelectorContainer"
      >
        <span className={styles.stakeLabel}>{label}</span>
        <div className={stakeSelectorClassName}>
          {stakeAmounts.map((stake) => {
            // Button is active when the stakeValue matches the button value and when a button has been clicked
            // instead of the amount being manually input. Otherwise inputting an amount the same as a button value leads to
            // awkward UI behaviour where the buttons highlight unexpectedly.
            const activeButton =
              stakeInput === "button" && stakeValue === stake;

            return (
              <button
                data-testid="betStakeButton"
                className={styles.styledButton}
                aria-pressed={activeButton}
                key={stake}
                value={stake}
                onClick={(event: React.MouseEvent) => {
                  handleStakeClick(event);
                  gaStakeSelectionEvent(event);
                }}
              >
                {currencySymbol}
                {stake}
              </button>
            );
          })}
        </div>
      </div>
      {!isBetBuilderOrStatsTipster ? (
        <div className={styles.stakeInputContainer}>
          <label className={styles.inputLabel} htmlFor="otherAmount">
            Other
          </label>
          <BetStakeInput
            stakeValue={stakeValue}
            stakeInput={stakeInput || ""}
            locale={locale}
            // passed the optional props as aliases due to these only being optional when the
            // user is not using the bet builder or the football tipster
            handleStakeChange={
              // eslint-disable-next-line no-unused-vars
              handleStakeChange as (event: ChangeEvent) => void
            }
            convertTo2Decimals={
              // eslint-disable-next-line no-unused-vars
              convertTo2Decimals as (event: ChangeEvent) => void
            }
            handleInputFocus={handleInputFocus as () => void}
          />
        </div>
      ) : null}
    </div>
  );
};
