import { ReactElement } from "react";

interface Props {
  size?: number;
  fill?: string;
  className?: string;
}

export const ArrowRight = ({
  size = 40,
  fill = "#15202C",
  className,
}: Props): ReactElement => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>Icons/Go Copy</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1440---Home" transform="translate(-1168.000000, -256.000000)">
        <g id="Group-2" transform="translate(0.000000, 128.000000)">
          <g id="Icons/Go-Copy" transform="translate(1168.000000, 128.000000)">
            <circle
              id="Oval"
              fill={fill}
              opacity="0.300000012"
              cx="16"
              cy="16"
              r="16"
            />
            <path
              d="M24.3017179,15.1693015 L19.5322867,10.2209027 C19.2520621,9.91270838 18.8259828,9.78096693 18.4201635,9.87704015 C18.0143441,9.97311336 17.6930278,10.2817924 17.5814853,10.6827333 C17.4699429,11.0836741 17.585832,11.513406 17.8839713,11.8043903 L20.7914165,14.8495588 L8.52927888,14.8495588 C7.89709869,14.8495588 7.38461538,15.3608219 7.38461538,15.9914969 C7.38461538,16.622172 7.89709869,17.1334351 8.52927888,17.1334351 L20.9364073,17.1334351 L17.7237184,20.0720226 C17.3569516,20.3245434 17.1704288,20.7664625 17.2456637,21.2046536 C17.3208985,21.6428448 17.6442131,21.9976441 18.0743135,22.1139982 C18.5044139,22.2303524 18.9631071,22.087108 19.2499364,21.7468653 L24.2101448,17.1791126 C24.4982996,16.9325129 24.6418734,16.5572453 24.5916993,16.18182 C24.667443,15.8172907 24.5590697,15.4388872 24.3017179,15.1693015 Z"
              id="Shape"
              fill="#B6CBD8"
              className={className}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
