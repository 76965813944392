import { ReactElement } from "react";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import { localisedBetType } from "@/lib/content";
import { ConditionalWrapper } from "@/components/ConditionalWrapper/ConditionalWrapper";
import styles from "@/components/BetType/BetType.module.scss";

interface BetTypeProps {
  selectionCount: number;
  emptySelectionsMessage?: string;
  isEmptySelections?: boolean;
}

export const BetType = ({
  selectionCount,
  emptySelectionsMessage,
  // isEmptySelections prop is needed to conditonally render the BetType wrapper.
  // Not enough to just render the wrapper when selectionCount is zero because it is set to zero when all selections are disabled
  // but we don't want to render the wrapper in this case because we still want to render the smaller-font/left-aligned design
  isEmptySelections = false,
}: BetTypeProps): ReactElement => {
  const [locale] = useLocaleFromRouter();
  const betType = localisedBetType({
    selectionCount: selectionCount,
    locale: locale,
    emptySelectionMessage: emptySelectionsMessage,
  });
  const wrapper = (children: ReactElement) => (
    <div className={styles.betType__container}>{children}</div>
  );

  return (
    <ConditionalWrapper
      condition={selectionCount === 0 && isEmptySelections}
      wrapper={wrapper}
    >
      <h4
        className={`${styles.betType} ${
          selectionCount > 0 ? "underlined" : ""
        } ${isEmptySelections ? styles.isEmptySelections : ""}`.trim()}
      >
        {betType}
      </h4>
    </ConditionalWrapper>
  );
};
